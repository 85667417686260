define([
  'hbs/handlebars',
  'modules/common/components/currency',

], (
  Handlebars, Currency,
) => {
  function currencyFormat(iso3, value) {
    return Currency.format(iso3, value);
  }
  Handlebars.registerHelper('common$currencyFormat', currencyFormat);
  return currencyFormat;
});
