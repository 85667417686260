define([
  '../hashTable',
  'underscore',
  '../../collections/menuItems',
],
(HashTable, _, MenuItems) => {
  const manager = function () {
    const self = this;
    this.map = new HashTable();
    this.get = function (name) {
      if (self.map.has(name)) {
        return self.map.get(name);
      }
      return self.map.add(name, new MenuItems());
    };
  };

  manager.prototype.name = 'common/components/managers/menu';
  return manager;
});
