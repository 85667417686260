define([
  'backbone',
  'jquery',
  'application',
  './component',
  'backbone.marionette',
],
(Backbone, $, App, Component) => {
  const lib = Component.extend({
    /**
             *
             * @param options
             */
    initialize(options) {
      this.order = [];
      this.ids = {};
    },
    /**
             * registers the module in the application
             * @param id
             * @param options
             * @returns {*}
             */
    register(id, options) {
      const name = this.getModuleName(id);
      const module = App.module(
        name,
        Backbone.Marionette.Module.extend(options),
      );

      if (!(name in this.order)) {
        this.order.push(name);
      }
      this.ids[name] = id;
      return module;
    },
    /**
             *
             * @param name
             */
    getModuleId(name) {
      return this.ids[name];
    },
    /**
             *
             * @param id
             */
    getModuleName(id) {
      return id.toString().replace(/[^a-z0-9]+/gi, '-');
    },
    /**
             * loops over the modules in the registration order
             * @param callback function(name, submodule)
             */
    each(callback) {
      $.each(this.order, (i, name) => {
        if (name in App.submodules) {
          callback(name, App.submodules[name]);
        }
      });
    },
  });
  return new lib();
});
