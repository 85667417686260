define([
  'jquery',
  'backbone',
  'module',
  './../behaviors/autoRegion',
  './../behaviors/crontab',
  './../components/layout/options',
],
($, Backbone, Module, AutoRegion, CronTab, LayoutOptions) => Backbone.Marionette.LayoutView.extend({
  name: 'modules/common/views/layout',

  behaviors: {
    autoRegion: {
      behaviorClass: AutoRegion,
    },
    crontab: {
      behaviorClass: CronTab,
    },
  },

  template() {
    return '';
  },

  getName() {
    return this.name;
  },

  initialize(options) {
    options = options || {};
    this.options = options = $.extend(true, {},
      this.defaults,
      LayoutOptions.getData(this.getName()),
      options);
  },
}));
