define([
  'jquery',
  'backbone',
  './data/currencies',
  'modules/common/components/locale',
],
($, Backbone, Data, Locale) => {
  const currencies = Backbone.Collection.extend({

    initialize() {
      this.add(Data);
    },

    nameContains(text) {
      return this.filter((model) =>
        // indexOf(-1) -> not found -> return 0 -> false
        // other are true
        ~model.get('name').toLowerCase().indexOf(text.toLowerCase()));
    },

    getCurrencySymbolUsingIso3(ISO3) {
      const currencyObj = this.getCurrencyModelUsingIso3(ISO3);

      if (currencyObj === undefined || currencyObj.get('symbol') === undefined) {
        return '';
      }

      return currencyObj.get('symbol');
    },

    getCurrencyObjectUsingIso3(ISO3) {
      const currencyModel = this.getCurrencyModelUsingIso3(ISO3);
      if (currencyModel) {
        return currencyModel.toJSON();
      }
      return null;
    },

    getCurrencyModelUsingIso3(ISO3) {
      return this.findWhere({ iso3: ISO3 });
    },

  });
  return new currencies();
});
