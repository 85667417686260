define([
  'jquery',
  'underscore',
  'backbone',
  'application',
  './component',
  './moduleLibrary',
],
($, _, Backbone, App, Component, ModuleLib) => {
  const module = Component.extend({
    defaultOptions: {
      startWithParent: false,
      version: '0.0.1',
      routers: {},
      routerClasses: {},
      subscribers: {},
    },
    /**
             *
             * @param options
             */
    initialize(options) {
      if (!options.id) {
        throw new Error('Module has no id set');
      }

      this.id = options.id;
      this.defaultOptions.onStart = this.onStart;
      this.options = $.extend({}, this.constructor.prototype.defaultOptions, options || {});
    },

    /**
             * On the start of the module
             * @param settings configuration of the module from config.json
             */
    onStart(settings) {
      const self = this;

      this.routers = this.routers || {};
      this.routerClasses = this.routerClasses || {};
      this.subscribers = this.subscribers || {};

      if (settings.disabledRouters !== undefined && _.isEmpty(this.routerClasses) === false) {
        $.each(this.routerClasses, (name, cls) => {
          if (settings.disabledRouters.indexOf(name) === -1) {
            self.routers[name] = new cls();
          }
        });
      } else if (_.isEmpty(this.routerClasses) === false) {
        $.each(this.routerClasses, (name, cls) => {
          self.routers[name] = new cls();
        });
      }
    },

    /**
             *
             * @returns {*}
             */
    getName() {
      return ModuleLib.getModuleName(this.id);
    },
    /**
             * @returns {*}
             */
    register() {
      return ModuleLib.register(this.id, this.options);
    },
  });
  return module;
});
