define(['hbs/handlebars', 'modules/common/components/locale', 'modules/common/components/moment'], (Handlebars, Locale, Moment) => {
  function compare(datetime, locale) {
    if (typeof locale !== 'string') {
      locale = Locale.getLocale();
    }

    return Moment(datetime).locale(locale).calendar();
  }

  Handlebars.registerHelper('common$calendarTime', compare);
  return compare;
});
