define([
  'moment',
  'modules/common/components/locale',
], (
  Moment, Locale,
) => {
  // Get locale
  let locale = Locale.getLocale();
  if (locale === 'en') locale = 'en-gb';

  // Set the locale on the moment
  Moment.locale(locale);
  // Return moment with correct locale
  return Moment;
});
