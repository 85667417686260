define([
  'jquery',
  'backbone',
  './data/languages',
  'modules/common/components/locale',
],
($, Backbone, Data, Locale) => {
  const languages = Backbone.Collection.extend({
    initialize() {
      let languagesObject = Data;
      languagesObject = this.flagObjItemName(languagesObject);
      this.add(languagesObject);
    },

    /* Translate the name of the languages */
    flagObjItemName(obj) {
      const self = this;
      $.each(obj, (key, value) => {
        value.flag = self.getLanguageFlag(value.iso2);
      });
      return obj;
    },

    /**
             * All though its a BAD idea to associate country flags to langauges. Some people REALLY want it.
             * Despise lots of outlets say its a sometimes disrespectful.
             * Ref;
             *  - http://www.flagsarenotlanguages.com/blog/why-flags-do-not-represent-language/
             * @param iso2
             * @return {string}
             */
    getLanguageFlag(iso2) {
      return `../images/language-flags/${iso2}.png`;
    },

    getTranslatedName(iso2) {
      const model = this.findWhere({ iso2 });
      if (undefined !== model) {
        return Locale.translate(model.get('key'));
      }
      return iso2;
    },

    getUsingIso2(iso2) {
      const model = this.findWhere({
        iso2,
      });
      if (model) {
        return model;
      }
    },

    getTranslatedCollection() {
      const collection = this.clone();
      collection.each((model) => {
        model.set('name', Locale.translate(model.get('key')));
      });
      return collection;
    },

    getCollectionFromArray(iso2Array) {
      const collection = new Backbone.Collection();

      const self = this;
      _.forEach(iso2Array, (iso2) => {
        const model = self.findWhere({ iso2 });
        if (model) {
          collection.add(
            model.toJSON(),
          );
        }
      });

      return collection;
    },

    nameContains(text) {
      return this.filter((model) =>
        // indexOf(-1) -> not found -> return 0 -> false
        // other are true
        ~model.get('name').toLowerCase().indexOf(text.toLowerCase()));
    },
  });
  return new languages();
});
