define(['hbs/handlebars', 'modules/common/components/locale', 'modules/common/collections/countries'], (Handlebars, Locale, Country) => {
  function country(iso2) {
    if (iso2) {
      return Country.getTranslatedName(iso2);
    }
    console.error(`Iso: "${iso2}" was not found.`);
    return iso2;
  }

  Handlebars.registerHelper('common$country', country);
  return country;
});
