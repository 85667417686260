define([
  'backbone',
  'underscore',
],
(Backbone, _) => Backbone.Marionette.Controller.extend({
  /**
             * Initialize the HashTable, when an object is passed add it to the table
             * @param obj
             */
  initialize(obj) {
    var obj = obj || [];

    this._length = 0;
    this._items = {};

    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        this._items[p] = obj[p];
        this._length++;
      }
    }
  },

  /**
             * Adds an item to the table if not exists, otherwise return it
             * @param key
             * @param value
             * @returns {*}
             */
  add(key, value) {
    if (this.has(key)) {
      return this._items[key];
    }
    this._length++;
    return this._items[key] = value;
  },

  /**
             * Overwrites the item if it was set before and returns the previous item
             * @param key
             * @param value
             * @returns {undefined}
             */
  set(key, value) {
    let previous;

    if (this.has(key)) {
      previous = this._items[key];
    } else {
      this._length++;
    }

    this._items[key] = value;
    return previous;
  },

  /**
             * Returns the item or undefined if it isn't set in the table
             * @returns {*}
             */
  get(key) {
    return this.has(key) ? this._items[key] : undefined;
  },

  /**
             * Is the item available in the table
             * @returns {*|boolean}
             */
  has(key) {
    return _.has(this._items, key);
  },

  /**
             * Removes and returns the item from the table
             * @returns {*}
             */
  remove(key) {
    if (this.has(key)) {
      const previous = this._items[key];
      this._length--;
      delete this._items[key];
      return previous;
    }

    return undefined;
  },

  /**
             * Return all keys from the table
             * @returns {Array}
             */
  keys() {
    return _.keys(this._items);
  },

  /**
             * Return all values from the table
             * @returns {Array}
             */
  values() {
    return _.values(this._items);
  },

  /**
             * Execute a function on each item from the table
             * @param fn
             */
  each(fn) {
    _.each(this._items, fn);
  },

  /**
             * Clear the table
             */
  clear() {
    this._items = {};
    this._length = 0;
  },
}));
