define(['underscore', 'hbs/handlebars', '../../components/locale'], (_, Handlebars, Locale) => {
  function translate(value) {
    if (value) {
      let params = [];
      if (arguments.length > 2) {
        if( arguments[1] instanceof Object ){
          params = arguments[1];
        } else {
          // get arguments without first which is the translations string
          // and last which is the handlebars.options
          for (let i = 1; i < arguments.length - 1; i++) {
            const escaped = _.escape(arguments[i]);
            params.push(escaped);
          }
        }
      }
      return new Handlebars.SafeString(
        Locale.translate(value, params),
      );
    }
    return '';
  }

  Handlebars.registerHelper('common$translate', translate);
  return translate;
});
