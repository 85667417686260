define([
        'jquery',
        'application',
        'backbone',
        'underscore'
    ],
    function ($, App, Backbone, _) {
        return Backbone.Marionette.Controller.extend({
            /**
             * Listen to the before and after trigger
             */
            initialize: function(){
                if(_.isFunction(this.before)) {
                    this.on('before', this.before)
                }

                if(_.isFunction(this.after)) {
                    this.on('after', this.after)
                }
            },

            /**
             * Render the layouts recursive
             * @param layouts
             */
            renderLayout: function(layouts){
                // make a reference to the main regionManager
                var regionManager = App;

                // region
                var region = null;

                $.each(layouts, function(){
                    this.options = this.options || {};

                    // initialize the layoutView which should be rendered
                    var layoutView = new this.layoutView(this.options );

                    // retrieve the region
                    region = regionManager.getRegion(this.regionName);

                    // if region is empty, not a layoutView or a different layoutView then needed
                    if((region.currentView === undefined) ||
                       (region.currentView.getRegion === undefined) ||
                       (region.currentView !== undefined &&
                        region.currentView.getName() != layoutView.getName())){
                        region.show(layoutView);
                    }
                    else{
                        // cleanup if we are not going to use it
                        delete layoutView;
                    }

                    // use the regionManager of the currentView for the next layoutView
                    regionManager = region.currentView;
                });
            },

            /**
             * Get the region by path
             * @param path
             * @returns {*}
             */
            getRegionByPath: function(path){
                // make a reference to the main regionManager
                var regionManager = App;

                // split the path by dot
                var splitted = path.split('.');

                // for saving the region
                var region = null;

                // for each found part find the region
                $.each(splitted, function(key, regionName){
                    // retrieve the region
                    region = regionManager.getRegion(regionName);

                    if ( region ){
                        // use the regionManager of the currentView for the next layoutView
                        regionManager = region.currentView;
                    } else {
                        region = null;
                        return false;
                    }
                });

                // return the region
                return region;
            }
        });
    });