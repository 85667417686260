define([
  './../components/component',
  'communicator',
],
(Component, Communicator) => Component.extend({
  name: null,

  options: {},

  initialize(options) {
    this.options = options;
  },

  getName() {
    return this.name;
  },

  getOptions() {
    return this.options;
  },

  setOptions(options) {
    this.options = options;
  },

  trigger() {
    Communicator.mediator.trigger(this.name, this);
  },
}));
