define([
  'backbone',
  'communicator',
],
(Backbone, Communicator) => Backbone.Marionette.Controller.extend({

  name: 'common/components/managers/region',

  initialize(options) {
    /* internal region manager */
    this._regionManager = new Backbone.Marionette.RegionManager();

    /* event API */
    Communicator.reqres.setHandler('RM:addRegion', this.addRegion, this);
    Communicator.reqres.setHandler('RM:removeRegion', this.removeRegion, this);
    Communicator.reqres.setHandler('RM:getRegion', this.getRegion, this);
  },

  /* add region facade */
  addRegion(regionName, regionId) {
    const region = this.getRegion(regionName);

    if (region) {
      return region;
    }

    return this._regionManager.addRegion(regionName, regionId);
  },

  /* remove region facade */
  removeRegion(regionName) {
    this._regionManager.removeRegion(regionName);
  },

  /* get region facade */
  getRegion(regionName) {
    return this._regionManager.get(regionName);
  },
}));
