define([
  'jquery',
  'backbone',
  'module',
  'modules/common/components/module',
  'application',
  './components/managers/region',
  './components/managers/cache',
  './components/bag',

  './components/cacheStore/localStorage',
  './components/locale',
  './crontabs/idle',
  './components/less',
  './events/preloader/ready',

  // for translations
  'modules/common/collections/data/languages.translation',
  'modules/common/collections/data/countries.translation',
  'modules/common/collections/data/error.translation',
  'modules/common/collections/data/currencies.name.translation',
  'modules/common/collections/data/currencies.name_plural.translation',
],
($, Backbone, Module, AppModule, App, RegionManager, CacheManager, Bag,
  LocalStorageCache, Locale, IdleCrontab, Less, PreloaderReadyEvent) => {
  const module = new AppModule({
    id: 'modules/common/application',

    initialize() {
      // register localStorage cache
      CacheManager.registerStore(LocalStorageCache);

      Backbone.Preloader.on('complete', () => {
        const event = new PreloaderReadyEvent();
        event.trigger();
      });
    },

    async onStart(options) {
      options = options || {};

      // Save our Bag
      App.bag = new Bag();

      // Also make a reference to the retrieving of objects
      App.com = function (component) {
        return App.bag.getByObj(component);
      };

      // Add our main region
      const regionManager = App.com(RegionManager);
      regionManager.addRegion('main', '#main');

      // Add our main region
      App.addRegions({
        main: '#main',
      });

      // add babysitter
      App.bag.add(
        'babysitter',
        new Backbone.ChildViewContainer(),
      );

      Module.crontabs = {
        idle: new IdleCrontab().start(),
      };

      await Locale.loadLocale(options.defaultLocale);

      if (App.settings.title) {
        $('title').text(App.settings.title);
      }

      if (options.less !== undefined && options.less.files !== undefined) {
        $.each(options.less.files, (key, file) => {
          let vars = {};

          if (options.less.variables !== undefined) {
            vars = options.less.variables;
          }

          Less.compile(file, vars);
        });
      }
    },
  });

  return module.register();
});
