define([
  'jquery',
  './component',
],
($, Component) => {
  const Cls = Component.extend({

    parse(v) {
      let ret = {};
      if (typeof v === 'string') {
        $.each(v.split('|'), (i, param) => {
          const v = param.split(':');
          if (v.length == 1) {
            // bool param
            ret[v[0]] = true;
          } else {
            ret[v[0]] = v[1];
          }
        });
      } else if (v) {
        ret = v;
      }
      return ret;
    },

    stringify(v) {
      const ret = [];
      if (typeof v !== 'string') {
        $.each(v, (k, vr) => {
          if (vr === true) {
            ret.push(k);
          } else {
            ret.push(`${k}:${vr}`);
          }
        });
      }
      return ret.join('|');
    },

  });

  return new Cls();
});
