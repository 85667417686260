define([
  './../components/component',
  'later',
  'modules/common/components/string',
],
(Component, Later, String) => Component.extend({
  cron: '* * * * *',

  timer: null,

  started: false,
  runOnStart: false,

  start() {
    const self = this;

    if (!this.started) {
      // if more then 4 spaces in the cron we specified the seconds, otherwise we used the default cron syntax
      const schedule = Later.parse.cron(this.cron, String.countOcccurrences(' ', this.cron) > 4);
      this.timer = Later.setInterval(() => {
        self.run();
      }, schedule);

      this.started = true;
      if (this.runOnStart) {
        this.run();
      }
    } else {
      throw new Error('Cron already running.');
    }

    return this;
  },

  ensureStarted() {
    if (!this.started) {
      this.start();
    }
  },

  stop() {
    if (this.timer !== null) {
      this.timer.clear();
      this.timer = null;
      this.started = false;
    }
  },

  run() {
    return true;
  },
}));
